import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Spring, config } from "react-spring/renderprops"
import Arrow from "../components/arrow"
import * as styles from "./about-seiu.module.css"
import { Container, Row, Col } from "react-bootstrap"
import ClientOnly from "../components/clientOnly"
import { StaticImage } from "gatsby-plugin-image"

export default function AboutSEIU({ data }) {
  var aboutSEIU1 = "../images/iStock-1198450495.jpg"
  var aboutSEIU2 = "../images/iStock-1277861476.jpg"
  var aboutSEIU3 = "../images/iStock-1214753465.jpg"

  const [destination, setDestination] = useState(-1)
  const isDestination = d => {
    return d === destination
  }
  //highlight-line
  return (
    <div>
      <SEO
        title="About SEIU-UHW | Forever Essential"
        description="SEIU-UHW is a healthcare justice union of more than 97,000 members, including frontline workers, patients, and healthcare consumers"
        fbHeadline="About SEIU-UHW | Forever Essential"
        fbDescription="We are honoring the legacies of fallen healthcare workers."
        twShare="SEIU-UHW represents the frontline healthcare workers who risk their lives battling the COVID-19 pandemic."
      />
      <ClientOnly>
        <Layout handlerDestination={setDestination}>
          {/* highlight-start */}
          <div className="section aboutseiu_section">
            <Spring
              delay={isDestination(0) ? 1500 : 0}
              to={{
                opacity: isDestination(0) ? 1 : 0,
                transform: isDestination(0)
                  ? "translateY(0)"
                  : "translateY(-200px)",
              }}
              config={config.molasses}
            >
              {props => (
                <div
                  style={{
                    ...props,
                    textAlign: "center",
                  }}
                >
                  <h1>
                    About
                    <br />
                    SEIU-UHW
                  </h1>
                </div>
              )}
            </Spring>
            <Spring
              delay={isDestination(0) ? 1500 : 0}
              to={{
                opacity: isDestination(0) ? 1 : 0,
                transform: isDestination(0)
                  ? "translateY(0)"
                  : "translateY(-200px)",
              }}
              config={config.molasses}
            >
              {props => (
                <div
                  style={{
                    ...props,
                    textAlign: "center",
                  }}
                >
                  <h1>
                    <Arrow
                      direction="down"
                      width="50px"
                      onClick={e => window.fullpage_api.moveSectionDown()}
                    />
                  </h1>
                </div>
              )}
            </Spring>
          </div>
          <div className="section aboutseiu_section">
            <div className={` ${styles.bgWaterColor}`}>
              <Spring
                delay={isDestination(1) ? 2000 : 0}
                to={{
                  opacity: isDestination(1) ? 1 : 0,
                }}
                config={config.molasses}
              >
                {props => (
                  <div style={props}>
                    <Container>
                      <h1>
                        SEIU-UHW is our collective voice as healthcare workers.
                        And during a crisis, that voice — that power — is more
                        important than ever.
                      </h1>
                    </Container>
                  </div>
                )}
              </Spring>
            </div>
          </div>
          <div className="section aboutseiu_section">
            <Container>
              <Row>
                <Col xs={12} md={6} lg={5}>
                  <Spring
                    delay={isDestination(2) ? 2200 : 0}
                    to={{
                      opacity: isDestination(2) ? 1 : 0,
                      transform: isDestination(2)
                        ? "translateX(0)"
                        : "translateX(-200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                          textAlign: "left",
                          paddingTop: "50px",
                        }}
                      >
                        <p>
                          We are a healthcare justice union of more than 97,000
                          members, including frontline workers, patients, and
                          healthcare consumers. As essential healthcare workers,
                          every day we bear witness to the devastating toll of
                          the COVID-19 pandemic – on our patients, our families,
                          and our co-workers. Through this memorial, we hope to
                          transform our private pain into public dignity and to
                          carry forward the legacies of those we’ve lost. May we
                          never forget.
                        </p>
                      </div>
                    )}
                  </Spring>
                  <Spring
                    delay={isDestination(2) ? 2200 : 0}
                    to={{
                      opacity: isDestination(2) ? 1 : 0,
                      transform: isDestination(2)
                        ? "translateX(0)"
                        : "translateX(-200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                          textAlign: "center",
                        }}
                      >
                        <div className={styles.imageContainer}>
                          <StaticImage
                            src={aboutSEIU1}
                            aspectRatio={1}
                            placeholder="blurred"
                            alt="Healthcare Nurse"
                          />
                        </div>
                      </div>
                    )}
                  </Spring>
                </Col>
                <Col xs={12} md={6} lg={7}>
                  <Spring
                    delay={isDestination(2) ? 2200 : 0}
                    to={{
                      opacity: isDestination(2) ? 1 : 0,
                      transform: isDestination(2)
                        ? "translateX(0)"
                        : "translateX(200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                          textAlign: "center",
                          padding: "50px 25px 25px",
                        }}
                      >
                        <div>
                          <StaticImage
                            src={aboutSEIU2}
                            placeholder="blurred"
                            alt="Vaccine with Masks"

                          />
                        </div>
                      </div>
                    )}
                  </Spring>
                  <Spring
                    delay={isDestination(2) ? 2200 : 0}
                    to={{
                      opacity: isDestination(2) ? 1 : 0,
                      transform: isDestination(2)
                        ? "translateX(0)"
                        : "translateX(200px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <div
                        style={{
                          ...props,
                          textAlign: "center",
                          padding: "25px",
                        }}
                      >
                        <div>
                          <StaticImage
                            src={aboutSEIU3}
                            placeholder="blurred"
                            alt="Healthcare Doctor"
                          />
                        </div>
                      </div>
                    )}
                  </Spring>
                </Col>
              </Row>
            </Container>
          </div>
        </Layout>
      </ClientOnly>
    </div>
  )
}

//highlight-start
export const pageQuery = graphql`
  query {
    allWpPage(sort: { fields: [date] }) {
      nodes {
        title
        slug
      }
    }
  }
`
//highlight-end
